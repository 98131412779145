import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const PaymentComponent = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadRazorpayScript = () => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.onload = () => {
          setIsLoading(false);
          resolve(true);
        };
        script.onerror = () => {
          setError("Failed to load Razorpay SDK");
          resolve(false);
        };
        document.body.appendChild(script);
      });
    };

    loadRazorpayScript();
  }, []);

  const handlePayment = () => {
    const options = {
      key: "rzp_test_aH9HtTLZnFJbme", // Razorpay test key
      amount: 50000, // Amount in paise (500 INR)
      currency: "INR",
      name: "Test Company",
      description: "Test Transaction",
      order_id: props.orderID, // This should be generated on your server
      handler: function (response) {
        console.log(response);
        toast.success("Payment Successful!");
/*         verifyPaymentOnServer(response);
 */   props.apiHandler(response.razorpay_payment_id)
      },
  
      prefill: {
        name: "John Doe",
        email: "john.doe@example.com",
        contact: "9999999999",
      },
      theme: {
        color: "#F37254",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const verifyPaymentOnServer = (paymentDetails) => {
    // Call your backend API to verify the payment status
    // You should pass `paymentDetails` to the backend for verification

    // Example using fetch (replace with your actual API endpoint):
    fetch("https://apis.yellowtaxi.in/api/v1/verify-payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        razorpay_payment_id: paymentDetails.razorpay_payment_id,
        razorpay_order_id: paymentDetails.razorpay_order_id,
        razorpay_signature: paymentDetails.razorpay_signature,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert("Payment Verified!");
        } else {
          alert("Payment Verification Failed!");
        }
      })
      .catch((error) => {
        console.error("Verification error:", error);
        alert("Error verifying payment");
      });
  };


  return (
    <div>
      <h1>Payment Page</h1>
      {isLoading && <p>Loading Razorpay...</p>}
      {error && <p>Error loading Razorpay: {error}</p>}
      <button style={{backgroundColor:"#f1c40f", padding:10, borderRadius:4, color:"black", fontSize:18, fontWeight:500, marginTop:30}} onClick={handlePayment} disabled={isLoading}>
        Continue with Razorpay
      </button>
    </div>
  );
};

export default PaymentComponent;
